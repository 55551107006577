export const MainNav = [
  {
    icon: "pe-7s-monitor",
    label: "Dashboard",
    to: "#/dashboard",
  },
  {
    icon: "pe-7s-graph",
    label: "Insight",
    to: "#/insight",
  },
  {
    icon: "pe-7s-search",
    label: "Search",
    to: "#/search",
  },/*
  {
    icon: "pe-7s-world",
    label: "APT Insights",
    to: "#/apt_insight",
  },*/
  {
    icon: "pe-7s-shield",
    label: "Blocklist",
    content: [
      {
        label: "Public Blocklists",
        to: "#/public",
      },
      {
        label: "Delist",
        to: "#/delist",
      },
      {
        label: "Report Abusive Ips",
        to: "#/report",
      },
      {
        label: "Custom Blocklists",
        to: "#/customlists",
      },
    ],
  }, /*
  {
    icon: "pe-7s-signal",
    label: "Threat Insights",
    to: "#/threat_insight",
  },
    {
    icon: "pe-7s-cash",
    label: "Pricing",
    to: "#/pricing",
  }, */
  {
    icon: "pe-7s-notebook",
    label: "Documentation",
    content: [
      {
        label: "User KB",
        to: "#/doc/kb",
      },
      {
        label: "API Reference",
        to: "#/doc/apiref",
      },
      {
        label: "Integrations",
        to: "#/doc/integrations",
      },
    ],
  },
  {
    icon: "pe-7s-info",
    label: "About Us",
    content: [
      {
        label: "Contact",
        to: "#/contact",
      },
      {
        label: "Legal Notice",
        to: "#/legal_notice",
      },
      {
        label: "Privacy Policy",
        to: "#/privacy_policy",
      },
      {
        label: "About Us",
        to: "#/about_us",
      },
    ],
  },
];