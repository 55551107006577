import decode from 'jwt-decode'

const config = {
  //api: "http://localhost:3000/"
  api: "https://api.listed.black/"
}

export default class AuthService {
  constructor() {
    this.api = config.api;
  }

  // Other methods...

  getApiUrl() {
    return this.api;
  }
  // Initializing important variables

  /*
  constructor(domain) {

    this.domain = domain || config.api // API server domain
    this.fetch = this.fetch.bind(this) // React binding stuff
    this.login = this.login.bind(this)
    this.getProfile = this.getProfile.bind(this)

    console.log(domain,this.domain)
  }*/

  login = async (login, password) => {

    const options = {
      'method': 'POST',
      'headers': {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
      'body': JSON.stringify({
        login,
        password
      })
    }

    const response  = await  fetch(`https://api.listed.black/reporter/login`, options)

    //const url = `${ api_url }/api/v1/email/two-factor`


    //const request = await fetch(url, options)
    const result = await response.json()

    if( result.token && result.token !== "" ) {
      this.setToken(result.token)

      const options = {
          'method': 'GET',
          'headers': {
            'Authorization': `Bearer ${result.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      }

      const url = `https://api.listed.black/reporter`

      const request = await fetch(url, options)
      let response = await request.json()

      console.log("response",response)
      if(response.success) {
          localStorage.setItem('name', response.user.reporter_name)
          localStorage.setItem('company', response.user.reporter_company)
          localStorage.setItem('email', response.user.reporter_email)
          localStorage.setItem('id', response.user.reporter_reporterid)
      }
    }

    return result
  }

  signup(data) {
    return this.fetch(`https://api.listed.black/reporter`, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((res) => {
      return Promise.resolve(res)
    })
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken() // GEtting token from localstorage
    return !!token && !this.isTokenExpired(token) // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token)
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true
      } else return false
    } catch (err) {
      return false
    }
  }

  setToken(idToken) {

    const decoded = decode(idToken)
    localStorage.setItem('id_token', idToken)
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token')
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token')
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken())
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.getToken()
    }

    return fetch(url, {
      headers,
      ...options
    })
      .then(this._checkStatus)
      .then((response) => response.json())
  }

  _checkStatus(response) {
    return response
    /*
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      
    } else {
      var error = new Error(response.statusText)
      error.response = response
      throw error
    }*/
  }
}
