import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import AuthService from '../../authService'
import ActorDetail from "../../Pages/ActorDetail";

const Dashboards = lazy(() => import("../../Pages/Dashboards"));
const Insights = lazy(() => import("../../Pages/Insights"))
const Search = lazy(() => import("../../Pages/Search"))
const Login = lazy( () => import("../../Pages/Login") )
const Register = lazy( () => import("../../Pages/Register") )

const APTInsight = lazy( () => import("../../Pages/APT_Insight") )
const PublicBlocklist = lazy( () => import("../../Pages/Public_blocklist") )
const Delist = lazy( () => import("../../Pages/Delist") )
const ReportAbusive = lazy( () => import("../../Pages/Report") )
const Pricing = lazy( () => import("../../Pages/Pricing") )
const CustomBlocklists = lazy( () => import("../../Pages/Custom_blocklists") )
const CustomBlocklistsAdd = lazy( () => import("../../Pages/Custom_blocklists_add") )
const ThreatInsight = lazy( () => import("../../Pages/Threat_insight") )
const Documentation = lazy( () => import("../../Pages/Documentation") )
const About = lazy( () => import("../../Pages/About_us") )
const Contact = lazy( () => import("../../Pages/Contact") )
const LegalNotice = lazy( () => import("../../Pages/Legal_notice") )
const PrivacyPolicy = lazy( () => import("../../Pages/Privacy_policy") )
const Integrations = lazy( () => import("../../Pages/Integrations") )
const APIRef = lazy( () => import("../../Pages/Apiref") )
const Kb = lazy( () => import("../../Pages/Kb") )
const SearchResults = lazy( () => import("../../Pages/Search_results") )

const FormWizardVar1 = lazy( () => import("../../Pages/Forms/Elements/Wizard/Examples/Variation1") )



const APIKeys = lazy( () => import("../../Pages/API_Keys") )
const Whitelist = lazy( () => import("../../Pages/whitelist") )
const Whitelisted = lazy( () => import("../../Pages/whitelisted") )
const Blocklisted = lazy( () => import("../../Pages/blocklisted") )

const Blocklist = lazy( () => import("../../Pages/blocklist") )
const Account = lazy( () => import("../../Pages/Account") )
const Hosts = lazy( () => import("../../Pages/hosts") )
const Reports = lazy( () => import("../../Pages/reports") )


const AppMain = () => {
    const Auth = new AuthService()

    //let redirectPath = "/login"
    let redirectPath = "/dashboard"

    /*
    if (Auth.loggedIn()) {
        redirectPath = "/dashboards/crm"
    } */

    return (
        <Fragment>

            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/register" component={Register}/>
            </Suspense>


            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/login" component={Login}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/dashboard" component={Dashboards}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/insight" component={Insights}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/actor/:actorId" component={ActorDetail}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/search" component={Search}/>
            </Suspense>


            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/apt_insight" component={APTInsight}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}    
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/public" component={PublicBlocklist}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}  
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/delist" component={Delist}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/report" component={ReportAbusive}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/} 
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/pricing" component={Pricing}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/} 
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/customlists" component={CustomBlocklists}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/threat_insight" component={ThreatInsight}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/} 
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/documentation" component={Documentation}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/about_us" component={About}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/contact" component={Contact}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/legal_notice" component={LegalNotice}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/privacy_policy" component={PrivacyPolicy}/>
            </Suspense>
             {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/} 
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/doc/apiref" 
                render={(props) => <APIRef />}
                />
            </Suspense>
             {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/} 
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/doc/kb" 
                render={(props) => <Kb {...props} selected={"3"} />}
                />
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/} 
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/doc/integrations" 
                render={(props) => <Integrations {...props} selected={"1"} />}
                />
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/ip/:searchIp" component={SearchResults}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/API_keys" component={APIKeys}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/whitelists" component={Whitelist}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/whitelist/:id" component={Whitelisted}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/customlist/:id" component={Blocklisted}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/hosts" component={Hosts}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/reports" component={Reports}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/blocklists" component={Blocklist}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/blocklist/new" component={CustomBlocklistsAdd}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/blocklist/edit/:id" component={CustomBlocklistsAdd}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user/account" component={Account}/>
            </Suspense>
            {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/}
            <Route exact path="/" render={() => (
                <Redirect to={ redirectPath }/>
            )}/>
        </Fragment>
    )
};

export default AppMain;
