import React, { Component, Fragment } from "react";
import { getGravatarUrl } from 'react-awesome-gravatar';
import {Redirect, Link} from 'react-router-dom'

import PerfectScrollbar from "react-perfect-scrollbar";
import AuthService from "../../../authService"

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";


import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../assets/utils/images/avatars/1.jpg";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.auth  = new AuthService()


    this.state = {
      active: false,
      logout:false,
    };
  }


  handleLogout = () => {
    this.auth.logout()
    this.setState({ logout:true })
  }

  render() {

   let logout = this.state.logout

   if(logout) {
      const path = window.location.href.split('/')
      if(path[4] !== "dashboard") {
        return (<Redirect to={{ pathname: '/dashboard' }} />)
      }
   }


  let box = ""

  if (!this.auth.loggedIn()) {
    box = (<div>
      <a href="/#/login">
      <Button className="btn btn-light">
                            Login
                          </Button></a>
    <a href="/#/register">
    <Button className="btn btn-primary">
      Sign Up
    </Button>
    </a>
    </div>
    )
  } else {

    const name = localStorage.getItem('name')
    const company = localStorage.getItem('company')
    const email = this.auth.getProfile().email

    let profileUrl = ""

    if(email) {
      profileUrl = getGravatarUrl(email);
    }

    box = (
      <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    <img width={42} className="rounded-circle" src={profileUrl} alt=""/>
                    <FontAwesomeIcon
                      className="ms-2 opacity-8"
                      icon={faAngleDown}
                    />
                  </DropdownToggle>
                  <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-warning">
                        <div className="menu-header-content text-start">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left me-3">
                                <img width={42} className="rounded-circle" src={profileUrl} alt=""/>
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  { name }
                                </div>
                                <div className="widget-subheading opacity-8">
                                  { company }
                                </div>
                              </div>
                              <div className="widget-content-right me-2">
                                <Button onClick={ this.handleLogout } className="btn-pill btn-shadow btn-shine" color="focus">
                                  Logout
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="scroll-area-xs"
                      style={{
                        height: "225px",
                      }}>
                      <PerfectScrollbar>
                        <Nav vertical>
                          <NavItem className="nav-item-header">
                            My Account
                          </NavItem>
                          <NavItem>
                            <NavLink href="/#/user/hosts">
                              Manage Registered Hosts
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="/#/user/reports">
                              Manage Active Reports
                            </NavLink>
                          </NavItem>                        
                          <NavItem>
                            <NavLink href="/#/user/API_keys">
                              Manage API keys
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="/#/user/account">
                              Account Settings
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="/#/user/account?change=pw">
                              Change Password
                            </NavLink>
                          </NavItem>                        
                        </Nav>
                      </PerfectScrollbar>
                    </div>
                    <Nav vertical>
                      <NavItem className="nav-item-divider mb-0" />
                    </Nav>
                    <div className="grid-menu grid-menu-2col">
                      <Row className="g-0">
                        <Col sm="6">
                        <Link to="/user/whitelists">
                          <Button   className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                            outline color="warning">
                            <i className="pe-7s-note2 icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> {" "} </i>
                            Manage my Whitelists
                          </Button>
                        </Link>
                        </Col>
                        <Col sm="6">
                          <Link to="/user/blocklists">
                            <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                              outline color="danger">
                              <i className="pe-7s-shield icon-gradient bg-love-kiss btn-icon-wrapper mb-2"> {" "} </i>
                              Manage my custom Blocklists
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ms-3 header-user-info">
                <div className="widget-heading">{ name }</div>
                <div className="widget-subheading">{ company }</div>
              </div>
            </div>
      )
  }

    return (
      <Fragment>
        <div className="header-btn-lg pe-0">
          <div className="widget-content p-0">
            { box }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserBox;
